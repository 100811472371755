/* src/FileUpload.css */
.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.header-container {
  text-align: center;
  margin-bottom: 20px;
}

.home-grid-title {
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
  background-color: #f7f7f7; /* Example background color */
  padding: 10px;
  border-radius: 8px;
}

.home-grid-title .lineoa {
  color: green;
}

.logo {
  height: 40px; /* Adjust as needed */
  margin-right: 10px;
  vertical-align: middle;
}

.subtitle {
  font-size: 0.8em;
  color: #666;
  margin-top: 5px;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropzone:hover {
  background-color: #f0f0f0;
}

.dropzone-icon {
  font-size: 40px;
  margin-bottom: 10px;
}

.upload-info {
  margin-top: 10px;
}

.file-list {
  width: 100%;
  margin-top: 20px;
  text-align: center; /* Ensure the text is centered */
}

.center-text {
  text-align: center;
}

.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.file-item:last-child {
  border-bottom: none;
}

.uploaded-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.progress-bar {
  width: 100%;
  margin-right: 10px;
}

.upload-complete {
  color: green;
}

.back-button-container {
  margin-top: 20px;
}