/* src/HomeGrid.css */
.home-grid {
  text-align: center;
  padding: 10px;
}

.home-grid-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
  background-color: #f7f7f7; /* Example background color */
  padding: 5px;
  border-radius: 20px;
}

.home-grid-title .lineoa {
  color: green;
}

.logo {
  height: 40px; /* Adjust as needed */
  margin-right: 10px;
  vertical-align: middle;
}

.subtitle {
  font-size: 0.8em;
  color: #666;
  margin-top: 5px;
  margin-bottom: 5px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  padding: 10px;
}

.grid-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.2s ease-in-out;
}

.grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
}

.grid-item:hover img {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 10px;
  transition: background 0.2s ease-in-out;
}

.grid-item:hover .overlay {
  background: rgba(0, 0, 0, 0.7);
}

.overlay p {
  margin: 0;
  font-size: 1.2em;
  font-weight: bold;
}
